
import { defineComponent } from 'vue';
import DashboardGrid from '@/components/DashboardGrid.vue';

enum DashboardOrderPrintType {
  General,
  FramedASF,
  UnframedASF,
  PrintOnly,
  PrintBespokeMounting,
  PrintBespokeFraming,
  PrintBespokeMixed,
}

export default defineComponent({
  name: 'OrdersTab',
  components: {
    DashboardGrid,
  },
  data() {
    return {
      dashboardOrderPrintTypeGeneral: {
        orderPrintType: DashboardOrderPrintType.General,
      },

      dashboardOrderPrintTypeFramedASF: {
        orderPrintType: DashboardOrderPrintType.FramedASF,
      },

      dashboardOrderPrintTypeUnframedASF: {
        orderPrintType: DashboardOrderPrintType.UnframedASF,
      },

      dashboardOrderPrintTypePrintOnly: {
        orderPrintType: DashboardOrderPrintType.PrintOnly,
      },

      dashboardOrderPrintTypeBespokeMounting: {
        orderPrintType: DashboardOrderPrintType.PrintBespokeMounting,
      },

      dashboardOrderPrintTypeBespokeFraming: {
        orderPrintType: DashboardOrderPrintType.PrintBespokeFraming,
      },

      dashboardOrderPrintTypeBespokeMixed: {
        orderPrintType: DashboardOrderPrintType.PrintBespokeMixed,
      },
    }
  }
});
