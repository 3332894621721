import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-84d83ff2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardGrid = _resolveComponent("DashboardGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardOrdersCountPartial',
      additionalData: _ctx.dashboardOrderPrintTypeGeneral,
      dataFunctionName: 'orderPrintTypeGeneral',
      exportUrl: 'form/grid/ExportOrdersCsv',
      exportName: 'Orders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: 'form/grid/DashboardFramedASFOrdersCountPartial',
      additionalData: _ctx.dashboardOrderPrintTypeFramedASF,
      dataFunctionName: 'orderPrintTypeFramedASF',
      exportUrl: 'form/grid/ExportOrdersCsv',
      exportName: 'Orders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: 'form/grid/DashboardUnframedASFOrdersCountPartial',
      additionalData: _ctx.dashboardOrderPrintTypeUnframedASF,
      dataFunctionName: 'orderPrintTypeUnframedASF',
      exportUrl: 'form/grid/ExportOrdersCsv',
      exportName: 'Orders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: 'form/grid/DashboardPrintOnlyOrdersCountPartial',
      additionalData: _ctx.dashboardOrderPrintTypePrintOnly,
      dataFunctionName: 'orderPrintTypePrintOnly',
      exportUrl: 'form/grid/ExportOrdersCsv',
      exportName: 'Orders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: 'form/grid/DashboardPrintOrdersBespokeMountingCountPartial',
      additionalData: _ctx.dashboardOrderPrintTypeBespokeMounting,
      dataFunctionName: 'orderPrintTypeBespokeMounting',
      exportUrl: 'form/grid/ExportOrdersCsv',
      exportName: 'Orders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: 'form/grid/DashboardPrintOrdersBespokeFramingCountPartial',
      additionalData: _ctx.dashboardOrderPrintTypeBespokeFraming,
      dataFunctionName: 'orderPrintTypeBespokeFraming',
      exportUrl: 'form/grid/ExportOrdersCsv',
      exportName: 'Orders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: 'form/grid/DashboardPrintOrdersBespokeMixedCountPartial',
      additionalData: _ctx.dashboardOrderPrintTypeBespokeMixed,
      dataFunctionName: 'orderPrintTypeBespokeMixed',
      exportUrl: 'form/grid/ExportOrdersCsv',
      exportName: 'Orders'
    }, null, 8, ["additionalData"])
  ]))
}